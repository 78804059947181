<script lang="ts">
export default { name: 'DefaultLayout' };
</script>
<script setup lang="ts">
const config = useRuntimeConfig();
if (config.public.storeId == 'professional-store') {
  useAuthGuardRedirection();
}
// Navigation for default theme
const { loadNavigationElements } = useNavigation();
const { data } = useAsyncData('mainNavigation', () => {
  return loadNavigationElements({ depth: 2 });
});
provide('swNavigation-main-navigation', data);

const { loadNavigationElements: loadFooterNavigationElements } = useNavigation({
  type: 'footer-navigation',
});
const { data: footerData } = useAsyncData('mainFooterNavigation', () => {
  return loadFooterNavigationElements({ depth: 2 });
});
provide('swNavigation-footer-navigation', footerData);
</script>
<template>
  <div>
    <LayoutHeader :header-headline="$t('header.default.headline')" />
    <LayoutNotifications />
    <div class="mx-auto">
      <slot />
    </div>
    <LayoutBreadcrumbs
      :breadcrumb-title="$t('header.default.headline')"
      breadcrumb-link="/"
      :show-arrow="true"
    />
    <LayoutFooter />
  </div>
</template>
